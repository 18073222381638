const tags = ['Blog']

// TODO - Is article meta going to pull article data as well?
export const siteName = "White Owl Education"

export const blogPost = {
    id: "2022-05-14-00",
    created_at: "2022-05-14T00:00:00.000-00:00",
    updated_at: "2022-05-14T00:00:00.000-00:00",
    page: {
        url: "https://www.whiteowleducation.com/blog/2022/05/14/build-ue5-workstation/"
    },
    author: {
        type: "Person",
        name: "White Owl Education",
        author_image_url: "https://whiteowleducation.com/images/authors/white-owl-education/white-owl-education-author-face.jpg",
        pathname: "images/authors/white-owl-education/white-owl-education-author-face.jpg",
        author_image_description: "Picture of Ralph Brooks, CEO of White Owl Education"
    },
    title: "Develop Apps with Hollywood-Level Graphics for less than $9 a Day",
    publisher: {
        name: siteName,
        url: "https://whiteowleducation.com/images/theme/header/logo/wo-logo-transparent.png"
    },
    description: "Use Amazon Managed Instances to get Unreal Engine v5 running with minimal hardware!",
    image: {
        url: "https://whiteowleducation.com/images/blog/2022/05/14/card-main-image.png",
        description: "Pictures showing how a heatmap (Integrated Gradients) identifies that a background is not part of what is needed to classify an image of a uniform."
    },
    internal_featured_image: {
        pathname: "images/blog/2022/08/05/main-page-image.png",
        description: "Picture of laptop running Unreal Engine from Amazon Web Services with the Cesium for Unreal plugin."
    },
    keywords: tags[0],
    featured: "no",
    read_time_minutes : "10",
    visibility: "public"
}