import React from 'react'
import { css } from '@emotion/css'
import tw, { GlobalStyles } from "twin.macro"

const tailwindStyle = css`
@tailwind base;
@tailwind components;
@tailwind utilities;
`;


const composedStyle = css`
    ${tailwindStyle};

`;


export function VirtualUnrealFAQ(props){
    return (
        <div className={composedStyle}>
            <GlobalStyles />
            <div>
                <h3 tw="font-Xpress text-2xl">Can Unreal Engine v5 run on a laptop?</h3>
                <p tw="pt-10 mb-8">If you have a high-end laptop, it is possible. If you have an average laptop, you are better off running Unreal Engine from a cloud server with GPUs  in Amazon Web Services, and connecting your laptop with a front-end client to AWS.</p>
            </div>
            <div>
                <h3 tw="font-Xpress text-2xl">What were the laptop specs that you used to test this Unreal Engine server?</h3>
                <p tw="pt-10 mb-8">I tested this approach using a Lenovo Thinkpad x1 Carbon, 7th generation to test this. This laptop has 16GB of memory, a Intel UHD Graphics 620 GPU with 7.9GB of GPU memory. When I just checked on eBay, a refurbished version of this laptop was going for $684. where I had download speeds of 228 Mbps download and 11 Mbps upload.</p>
            </div>
        </div>
    )
}